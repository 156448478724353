
































































































































































































































































































































































































































































































































































import { Base } from "@/store/API/api";
import { UserDocuments } from "@/store/models/user";
import { DemandSupplyStore, JWTStore, UserStore } from "@/store/modules";
import { Rule, Validator } from "vee-validate";
import { MetaInfo } from "vue-meta";
import { Component, Vue } from "vue-property-decorator";


Validator.extend("checkLicenseExists", {
  /** message is taken from traslation files */
  validate: async (value: any, args: any[]) => {
    if (value) {
      await JWTStore.licenseNoExists(value);
      return !JWTStore.LicenseNoExists;
    }
    return false;
  }
} as Rule);

@Component({
  metaInfo(): MetaInfo {
    return {
      title: `${this.$t("companyMenu")}`,
      meta: [
        { name: "description", content: "Company Panel", vmid: "companyScreen" },
        { name: "viewport", content: "width=device-width, initial-scale=1" }
      ]
    };
  }
})
export default class Adminmenu extends Vue {
  imgFallbackLink: string = "";

  hasSeedLicense: any = true;
  radioModel: any = true;
  changeRadio(bool: any) {
    this.hasSeedLicense = bool;
  }
  seedCompanyLicense: File[] = [] as File[];
  trainingApplication: File[] = [] as File[];
  companyRegistrationRenewalCertificate: File[] = [] as File[];
  tax: File[] = [] as File[];
  purchaseProof: File[] = [] as File[];
  pan: File[] = [] as File[];
  chequeBookCover: File[] = [] as File[];
  otherDocument: File[] = [] as File[];
  districtApproval: File[] = [] as File[];

  userDocuments: UserDocuments = {} as UserDocuments;


  get user() {
    return UserStore.User;
  }

  roleChecker(roles: String[]) {
    for (let role of roles) {
      if (this.user.role === role) {
        return true;
      }
    }
    return false;
  }

  get crops() {
    const subsidy = DemandSupplyStore.SubsidyCrops;
    console.log(subsidy, this.user.role);
    const allowed_seed_type = this.roleChecker(['improved_seed_producer', 'improved_seed_consumer'])? 4: 2;
    const crops = subsidy.filter(x=> x.allowed_seed_types?.map(x=> x.seed_type).includes(allowed_seed_type)).map(x => {
      return {
        id: x.crop_type,
        name: x.crop_name,
        seedType: x.seed_type,
        cropImg: x.crop_img
      };
    });
    return crops;
  }

  setAltCropImg(event: any) {
    event.target.src = require("@/assets/crops.svg");
  }

  get userDetail() {
    return UserStore.UserDetail;
  }

  get requiredReapproval() {
    return (this.userDetail.reapproval_required && ['pending', 'rejected'].includes(this.userDetail.reapprove_status))
  }

  get waitingForApproval() {
    return (this.userDetail.reapproval_required && ['applied', 'reapplied'].includes(this.userDetail.reapprove_status))
  }

  handleAfterUploadSeedCompanyLicense(file: any, fileList: any[]) {
    this.seedCompanyLicense = [];
    this.seedCompanyLicense.push(file.raw);
  }

  handleAfterUploadCompanyRegistrationRenewalCertificate(file: any, fileList: any[]) {
    this.companyRegistrationRenewalCertificate = [];
    this.companyRegistrationRenewalCertificate.push(file.raw);
  }

  handleAfterUploadTax(file: any, fileList: any[]) {
    this.tax = [];
    this.tax.push(file.raw);
  }

  handleAfterUploadTrainingApplication(file: any, fileList: any[]) {
    this.trainingApplication = [];
    this.trainingApplication.push(file.raw);
  }

  created() {
    DemandSupplyStore.getSubsidyCrops({ lang: this.$i18n.locale });
  }
  downloadFile(url: string, name: string = "") {
    if (url != "") {
      const link = document.createElement("a");
      link.href = Base + url;
      if (name != "") {
        link.setAttribute("download", name);
      }
      document.body.appendChild(link);
      link.click();
    }
  }

  async onDocumentUpdate() {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
        let documents = new FormData();
        if (this.userDocuments.seed_license_no && this.userDocuments.seed_license_no != ''){
          documents.append("seed_license_no", this.userDocuments.seed_license_no)
        }

        for (let file of this.seedCompanyLicense) {
          documents.append("company_license", file);
        }

        for (let file of this.companyRegistrationRenewalCertificate) {
          documents.append("company_registration_renewal_certificate", file);
        }

        for (let file of this.tax) {
          documents.append("tax", file);
        }

        for (let file of this.trainingApplication) {
          documents.append("training_application", file);
        }

        await UserStore.updateUserDocuments(documents).then(() => {
          UserStore.getUser();
        }).catch((reason: any) => {
          this.$notify.error({
            title: this.$t("registrationFailedTitle").toString(),
            message: this.$t("registrationFailedMessage").toString()
          });
        });
    } else {
      document
        .getElementsByName(this.$validator.errors.items[0].field)[0]
        .scrollIntoView({ behavior: "smooth", block: "center" });
      this.$notify.error({
        title: this.$t("invalidDataTitle").toString(),
        message: this.$t("invalidDataMessage").toString()
      });
    }
  }

  checkIfRequired(event: any) {
    let index = this.$validator.fields.items.findIndex(x => x.name == event);
    if (index >= 0 && this.$validator.fields.items[index].flags["required"]) {
      return true;
    }
    return false;
  }
}
